import styles from "./index.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css";
import { Pagination } from "swiper";
import blog6Pic from "@images/blog6Pic.png";
import Image from "next/image";
import dayjs from "dayjs";
import {getLangLink} from '@/utils/lang'
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import one from "@images/home/22@2x.png";
import { useWidth } from "@/utils/resize";
const PCNews = (props: any) => {
    const list = props?.list;
    const router = useRouter()
    const { locales, locale: activeLocale } = router;
    const { t } = useTranslation("home")
    const { width, display } = useWidth();
    return (
        <div className={styles.blogNew + " defeaultContainer"}>
            <h2 className={styles.title}>{t('Latest TikTok Trends and Guides')}</h2>
            <div className={styles.blogList}>
                {list?.map((blog, index) => {
                    return (
                        <a
                          key={index}
                          href={getLangLink(`/blogs/${blog.seoUrl}`, activeLocale)}
                          title={blog.seoTitle}
                          className={styles.blogItem}
                        >
                          <div className={styles.item} key={index}>
                            <div className={styles.itemTop}>
                              <div className={styles.pic}>
                                <a
                                  href={getLangLink(
                                    `/blogs/${blog.seoUrl}`,
                                    activeLocale
                                  )}
                                  title={blog.seoTitle}
                                  className={` block leading-none relative`}
                                >
                                  <Image
                                    lazyBoundary="10px"
                                    src={blog.cover ? blog.cover : one}
                                    alt={blog.seoTitle}
                                    width={width > 1024 ? 335 : 117}
                                    height={width > 1024 ? 234 : 82}
                                    objectFit="cover"
                                    style={{
                                      borderRadius: width < 1024 ? "8px" : "0px",
                                    }}
                                  ></Image>
                                </a>
                              </div>
                              <div className={styles.itemInfo}>
                                <div className={styles.title}>{blog.title}</div>
                                <div className={styles.time}>
                                  {blog.createTime
                                    ? dayjs(blog.createTime).format("YYYY-MM-DD ")
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className={styles.itemBottom}>
                              <div
                                className={
                                  styles.desc +
                                  " lg1024:!min-h-0 lg1024:!text-[14px] lg1024:!leading-[20px]"
                                }
                              >
                                {blog.desc}
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                })}
                {/* <a href={getLangLink(`/blogs`, activeLocale)} target={"_blank"} rel="noreferrer">
                    <div className={styles.blog}>
                        <div className={styles.imgBox}>
                            <Image
                                title="博客列表"
                                src={blog6Pic}
                                objectFit="cover"
                                alt="博客列表"
                            />
                        </div>
                        <div className={styles.mask}></div>
                        <div className={styles.bottom0 + " flex items-center"}>
                        {t("View More")}
                            <i className="iconfont icon-youjiantou"></i>
                        </div>

                        <div
                            className={
                                styles.hoverBox +
                                " flex flex-col justify-between"
                            }
                        >
                            <div></div>
                            <div className={styles.bottom1}>
                            {t("View More")}
                                <i className="iconfont icon-a-73xiayibu"></i>
                            </div>
                        </div>
                    </div>
                </a> */}
            </div>
        </div>
    );
};

const MNews = (props: any) => {
    const list = props?.list;
    const router = useRouter()
    const { locales, locale: activeLocale } = router;
    const {t} = useTranslation("home")
    return (
        <div className={styles.mNews}>
            <h2 className={styles.titleh2}>{t('Latest TikTok Trends and Guides')}</h2>
            <Swiper
                spaceBetween={12}
                slidesPerView={"auto"}
                modules={[Pagination]}
                style={{ paddingBottom: "1.36rem" }}
                pagination={{
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                    bulletClass: ` ${styles.myBullet} swiper-pagination-bullet`,
                    bulletActiveClass: `${styles.myActiveBullet} swiper-pagination-bullet-active`,
                }}
            >
                {list?.map((blog, index) => {
                    return (
                        <SwiperSlide
                            key={index}
                            style={{
                                width: "8rem",
                            }}
                        >
                            <a
                                className={styles.blog}
                                href={getLangLink(`/blogs/${blog?.seoUrl}`,activeLocale)}
                                title={blog?.seoTitle}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <div className={styles.imgs}>
                                    <img
                                        title={blog?.seoUrl}
                                        src={blog?.cover}
                                        object-fit="cover"
                                        alt={blog?.seoUrl}
                                    />
                                </div>
                                <div className={styles.infos + ""}>
                                    <div
                                        className={styles.title + " ellipse-2"}
                                    >
                                        {blog?.title}
                                    </div>
                                    <div className={styles.desc + " ellipse-2"}>
                                        {blog?.desc}
                                    </div>
                                </div>
                            </a>
                        </SwiperSlide>
                    );
                })}
                <div
                    className={`swiper-pagination ${styles.myPagination}`}
                ></div>
            </Swiper>
            <a
                className={styles.more + " flex items-center"}
                href={getLangLink("/blogs",activeLocale )}
                target={"_blank"}
                rel="noreferrer"
            >
                {t("View More")}
            </a>
        </div>
    );
};
const Index = (props: any) => {
    const list = props?.list?.items;
    return (
        <div className={styles.index}>
            <PCNews list={list}></PCNews>
            <MNews className={styles.mNews} list={list} />
        </div>
    );
};

export default Index;
